<script>
export default {
  name: 'Stepper',

  computed: {
    routeName() {
      return this.$route.name
    }
  }
}
</script>

<template>
  <section class="onboarding-stepper">
    <ul class="d-flex justify-between p-0 m-0">
      <li class="step step-1 d-flex" :class="{ 'active': routeName === 'registration.woocommerce.select-store-type' }">
        <div class="step-number">1</div>
        <div class="step-content">
          <h2 class="mb-0">Store Type</h2>
          <h3 class="mb-0">Source or destination?</h3>
        </div>
      </li>
      <li class="step step-2 d-flex" :class="{ 'active': routeName === 'registration.woocommerce.verify-store-url' }">
        <div class="step-number">2</div>
        <div class="step-content">
          <h2 class="mb-0">Connect Store</h2>
          <h3 class="mb-0">Verify your store</h3>
        </div>
      </li>
      <li class="step step-3 d-flex" :class="{ 'active': routeName === 'registration.woocommerce.permissions' }">
        <div class="step-number">3</div>
        <div class="step-content">
          <h2 class="mb-0">Permissions</h2>
          <h3 class="mb-0">Review store access</h3>
        </div>
      </li>
    </ul>
  </section>
</template>
