<script>
import { mapGetters, mapState } from 'vuex'
import { REGISTER_SHOP } from '@/store/actions.type'
import OnboardingCard from '../../../registration/OnboardingCard.vue'
import OnboardingHeading from '../../../registration/OnboardingHeading.vue'
import Stepper from '../../../registration/Stepper.vue'

export default {
  name: 'VerifyStoreURL',

  data() {
    return {
      isStatusOk: null,
      isValidUrl: false,
      isAccountURLHelpVisible: false,
      loading: false,
      storeUrl: '',
      rules: {
        required: value => !!value || 'This field is required, enter a URL and try again',
        url: value => {
          const pattern  = /^((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/
          return pattern.test(value) || 'Please enter a valid URL starting with https://'
        },
      },
      validForm: false,
      storeAlreadyUsedError: ''
    };
  },

  components: {
    OnboardingCard,
    OnboardingHeading,
    Stepper
  },

  mounted() {
    if(!this.newShop.type) {
      this.$router.push({ name: 'registration.woocommerce.select-store-type' })
    }
  },

  computed: {
    ...mapGetters('auth', ["currentUserID", "currentUser"]),
    ...mapState('shop', ["newShop"]),

    isValidURL() {
      //const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
      const pattern = /^((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/
      return pattern.test(this.storeUrl)
    }
  },

  methods: {
    storeNotFound() {
      return (this.isStatusOk || this.isStatusOk === null) || 'WooCommerce account not found, enter another address and try again'
    },

    reset(){
      this.isValidUrl = false;
      this.inValidUrl = false;
    },

    validateURL(){
      this.loading = true;
      let config = {
        method: "HEAD",
        mode: "no-cors"
      };
      const pingRequest = new Request(this.storeUrl, config);
      fetch(pingRequest)
      .then(response => {
        if(response.status === 0) {
          this.isValidUrl = true
          this.isStatusOk = true
        } else {
          this.isValidUrl = false
          this.isStatusOk = false
        }

        this.loading = false;
        this.storeNotFound()
      })
      .catch(e => {
        this.isValidUrl = false
        this.isStatusOk = false
        this.loading = false
        this.storeNotFound()
      });
    },

    async registerStore() {
      let withoutHttps, shopType;
      withoutHttps = this.storeUrl.replace(/^http(s)*:\/\//, "")
      withoutHttps = withoutHttps.replace(/\/$/, "")
      shopType = this.newShop.type
      try {
        const response = await this.$store.dispatch(`shop/${REGISTER_SHOP}`, {
          user_id: this.currentUserID,
          url: withoutHttps,
          platform_api_version: "v3",
          platform: "woocommerce",
          type: shopType
        })
        let redirectUrl = response.redirect_url;
        redirectUrl += process.env.VUE_APP_WOO_REDIRECT_URI + '?store=' + withoutHttps + '&type=' + shopType;
        window.location.href = redirectUrl;
      } catch (error) {
        this.storeAlreadyUsedError = error.data.errors[0]
      }
    },

    onInputHandler() {
      this.isStatusOk = true
      this.storeNotFound()
      this.storeAlreadyUsedError = ''
    }
  }
}
</script>

<template>
  <section class="onboarding v2 verify-url">
    <Stepper />
    <router-link to="/registration/woocommerce/continue-later" class="btn-later">
      Signout, continue later
    </router-link>

    <div class="p-t-5">
      <OnboardingHeading heading="Connect your WooCommerce store" />
    </div>

    <p class="onboarding-text m-t-2">
      Enter your WooCommerce Store's Admin URL to start the verification process
    </p>

    <OnboardingCard large>
      <v-form v-model="validForm" class="w-100 d-flex verify-url-form">
        <v-text-field
          :rules="[rules.required, rules.url, storeNotFound()]"
          @click:clear="reset"
          class="onboarding-input"
          clearable
          dense
          hint="You'll need to add https:// to the URL"
          label="Store URL"
          outlined
          persistent-hint
          v-model="storeUrl"
          @input="onInputHandler" />

        <v-btn
          :disabled="!isValidURL"
          :loading="loading"
          @click="validateURL"
          class="onboarding-btn"
          color="primary"
          elevation="0"
          v-if="!isValidUrl">
          Link URL
        </v-btn>

        <div v-else class="url-linked">
          URL linked
        </div>
      </v-form>

      <section v-if="!isValidUrl" class="account-url-help">
        <div class="account-url-help-header" @click="isAccountURLHelpVisible = !isAccountURLHelpVisible">
          <span class="help-icon">?</span>
          Where can I find my WooCommerce account URL?
          <span v-if="isAccountURLHelpVisible" class="help-indicator">-</span>
          <span v-else class="help-indicator">+</span>
        </div>

        <div class="account-url-help-body" v-if="isAccountURLHelpVisible">
          <p>Syncio requires the URL associated with the WordPress admin tied to your WooCommerce plugin.</p>
          <p>You can find the URL by logging into your WordPress site via the account tied to the site administrator.</p>
          <p>Once logged in, copy the URL in the address bar and paste in the Verify URL field above.</p>

          <img src="@/assets/images/url-locator.png" alt="Url locator image" class="url-locator-image">

          <div class="url-note-list">
            *Note the following URLs will not work:
            <ul>
              <li>Redirect URLs for your webstore</li>
              <li>URLs associated with your WordPress blog</li>
            </ul>
          </div>
        </div>
      </section>

      <div v-if="isValidUrl" class="text-center url-linked-text m-t-2">
        <p class="lg">Next step is to approve permissions in Woo</p>
        <p>In order to send and receive stock and updates between the stores you've connected to, <br> Syncio requires Read & Write permissions for an API Key and Webhooks.</p>
        <p>Syncio will only use these permissions to perform essential product and order updates.</p>
        <p>On the next step, make sure you login to your admin account with:</p>
        <p class="lg">Read and Write permissions</p>

        <v-btn
          @click="registerStore()"
          block
          class="onboarding-btn m-b-4 m-t-2"
          color="primary"
          elevation="0">
          Continue to permissions approval
        </v-btn>

        <p v-if="storeAlreadyUsedError" style="margin-bottom: 12px !important; color: #D91E18 !important;">
          The store is already installed with another account
          <br>
          Please try again
        </p>
      </div>
    </OnboardingCard>
  </section>
</template>
