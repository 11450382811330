<script>
export default {
  name: 'onboardingCard',

  props: {
    small: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <aside class="onboarding-card" :class="{ 'sm': small, 'md': medium, 'lg': large }">
    <slot></slot>
  </aside>
</template>
