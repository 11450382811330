<script>
export default {
  name: 'onboardingHeading',

  props: {
    heading: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
  <div class="text-center">
    <h1 class="m-b-0 m-t-3">{{ heading }}</h1>
  </div>
</template>
